import DishSelect from './Form/DishSelect';
import Container from 'react-bootstrap/Container';

function App() {
  return (
    <Container >
    
      <h1>Dishes App</h1>
      < DishSelect />
   
    </Container>
  );
}

export default App;
